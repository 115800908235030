var cartDataApi = function () {

};

cartDataApi.prototype = {

    actions: {
        add: '/cart/add?format=json',
        remove: '/cart/remove?format=json',
        increment: '/cart/increment?format=json',
        decrement: '/cart/decrement?format=json',
        update: '/cart/update?format=json'
    },
    products: {},

    /**
     * Add item (instance of product object) to cart
     *
     * @param object product
     *
     */

    // --------------------------------------------------------------------

    add: function (product, callback, error_callback, async) {

        if (typeof product == 'object' && !this.isInCart(product.getId())) {
            this.products[product.getId()] = product;
        }
        // Send

        $.ajax({
            type: 'post',
            url: this.actions.add,
            data: {product_id: product.getId(), quantity: product.getQuantity(), options: product.getOptions()},
            dataType: 'json',
            async: async === undefined ? true : async,

            success: function (response) {
                if (response.state == 'success') {
                    if (callback !== undefined) {
                        callback.call(false, response);
                    }

                    // Trigger custom global event
                    $(document).trigger('afterCartAdd', [response.cart]);
                } else {
                    if (error_callback !== undefined) {
                        error_callback.call(false, response);
                    }
                }
            }
        });

    },

    /**
     * Remove item from cart
     *
     * @param int product_id
     *
     */

    // --------------------------------------------------------------------

    remove: function (entry_id, callback) {
        $.ajax({
            type: 'post',
            url: this.actions.remove,
            data: {entry_id: entry_id},
            dataType: 'json',

            success: function (response) {
                if (response.state == 'success') {
                    if (callback !== undefined) {
                        callback.call(false, response);
                    }

                    // Trigger custom global event
                    $(document).trigger('afterCartRemove', [response.cart]);
                }
            }
        });
    },

    /**
     * Get cart products
     *
     * @param  int product_id
     * @return object
     *
     */

    // --------------------------------------------------------------------

    getProducts: function () {
        return this.products;
    },

    /**
     * Get cart product
     *
     * @param  int product_id
     * @return object
     *
     */

    // --------------------------------------------------------------------

    getProduct: function (product_id) {
        return this.products[product_id] !== undefined ? this.products[product_id] : false;
    },

    /**
     * Get cart sum price
     *
     * @return int
     *
     */

    // --------------------------------------------------------------------

    getSum: function () {
        var sum = 0;

        if (this.products.length > 0) {
            $.each(this.products, function (i, product) {
                sum += product.getPrice();
            });
        }

        return sum;
    },

    isInCart: function (product_id) {
        return this.products[product_id] !== undefined;
    },

    increment: function (entry_id, quantity, callback) {
        $.ajax({
            type: 'post',
            url: this.actions.increment,
            data: {entry_id: entry_id, quantity: quantity},
            dataType: 'json',

            success: function (response) {
                if (response.state == 'success') {
                    if (callback !== undefined) {
                        callback.call(false, response);
                    }

                    // Trigger custom global event
                    $(document).trigger('afterCartIncrement', [response.cart]);
                }
            }
        });
    },

    decrement: function (entry_id, quantity, callback) {
        $.ajax({
            type: 'post',
            url: this.actions.decrement,
            data: {entry_id: entry_id, quantity: quantity},
            dataType: 'json',

            success: function (response) {
                if (response.state == 'success') {
                    if (callback !== undefined) {
                        callback.call(false, response);
                    }

                    // Trigger custom global event
                    $(document).trigger('afterCartDecrement', [response.cart]);
                }
            }
        });
    },

    update: function (entry_id, quantity, callback) {
        $.ajax({
            type: 'post',
            url: this.actions.update,
            data: {entry_id: entry_id, quantity: quantity},
            dataType: 'json',

            success: function (response) {
                if (response.state == 'success') {
                    if (callback !== undefined) {
                        callback.call(false, response);
                    }


                    // Trigger custom global event
                    $(document).trigger('afterCartUpdate', [response.cart]);
                }
            }
        });
    }

};
