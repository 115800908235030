var cart_display_api = new cartDisplayApi(),
    cart_data_api = new cartDataApi();

$(document).on('click', '.add-to-cart-btn', function (event) {
    event.preventDefault();

    var btn_reference = $(this),
        item_qty = $(this).val(),
        item_id = parseInt(btn_reference.data('product'));

    btn_reference.attr('disabled', 'disabled');

    if (!isNaN(item_id)) {
        var getCartContainer = function getCartContainer() {
            if ($('#mainNavbar:visible').length == 0 && $('#mobile-cart-btn').length != 0) {
                return $('#mobile-cart-btn');
            } else if ($('#main-menu-row').hasClass('scrolled') && $('#cart-container-scrolled').length != 0) {
                return $('#cart-container-scrolled');
            } else if ($('#cart-container').length != 0) {
                return $('#cart-container');
            }
            return $('#logo');
        };

        var options = {};

        if (btn_reference.is('[data-upsell-id]')) {
            options.upsell_id = parseInt(btn_reference.data('upsell-id'));
        }

        var cart_product = new Product({
            id: item_id,
            quantity: item_qty
        });

        cart_product.setOptions(options);

        var cartContainer = getCartContainer();

        var imgtodrag = $(btn_reference).closest('.product-item').find("img").eq(0);

        if (imgtodrag) {
            var imgclone = imgtodrag.clone().offset({
                top: imgtodrag.offset().top,
                left: imgtodrag.offset().left
            }).css({
                'opacity': '0.7',
                'position': 'absolute',
                'height': 'auto',
                'width': '150px',
                'z-index': '100'
            }).appendTo($('body')).animate({
                'top': cartContainer.offset().top + cartContainer.height() / 2,
                'left': cartContainer.offset().left + cartContainer.width() / 2,
                'width': 75,
                'height': 'auto'
            }, 300, 'easeInOutExpo');

            imgclone.animate({
                'width': 0,
                'height': 0
            }, function () {
                $(this).detach();
            });
        }

        cart_data_api.add(cart_product, function (response) {

            updateCartInfo(response.cart);

            if (window.location.href == window.location.origin + '/kosar') {
                location.reload();
            } else {

                $('header .cart-counter').effect("shake", {
                    times: 2,
                    distance: 7
                }, 200);
                btn_reference.removeAttr('disabled');

                if (btn_reference.is('[data-upsell-id]')) {
                    $.fancybox.close();
                }
            }
        }, function () {
            swal('Oops...', 'Hiba a termék kosárba helyezése során!', 'error');
        });
    } else {
        swal('Oops...', 'Nem kaptam meg a product_id-t!', 'error');
    }
    event.stopImmediatePropagation();
});

$('#cart .btn.btn-remove').on('click', function (event) {
    event.preventDefault();

    var btn_reference = $(this),
        cart_data_api = new cartDataApi(),
        item_name = btn_reference.data('productName'),
        entry_id = parseInt(btn_reference.data('entryId')),
        cart_row = $("#cart-row-" + btn_reference.data('entryId'));

    btn_reference.attr('disabled', 'disabled');

    swal({

        title: 'Biztos vagy benne?',
        html: 'Biztosan törlöd a kosaradból a(z) <br/>"' + item_name + '"<br/>megnevezésű terméket?',
        type: 'question',
        showCancelButton: true,
        confirmButtonText: 'Biztos, nem kérem!',
        cancelButtonText: 'Mégsem',
        confirmButtonClass: 'btn btn-primary pull-right',
        cancelButtonClass: 'btn btn-secondary pull-left',
        buttonsStyling: false,
        allowOutsideClick: false

    }).then(function () {
        cart_data_api.remove(entry_id, function (response) {
            cart_row.fadeOut(300, function () {
                // refreshCart(response.cart);
                cart_row.remove();
                if (response.cart.entry_count == 0) {
                    window.location = '/kosar';
                } else {
                    cart_display_api.initalize(response.cart).redrawTable();
                }
            });
        });
    }).catch(function (reason) {
        btn_reference.removeAttr('disabled');
    });
});

// Init cart event listener

$(document).on('afterCartChange', function (e, cart) {
    cart_display_api.redrawDiscount().redrawShipping();

    updateBruttoCreditedSumWithShipping();
    var selected_shipping = $('.shipping-method-select:checked');
});

function updateEntriesPrice(entry) {
    $('#brutto-couponed-price-' + entry.id).html(entry.brutto_couponed_price.toLocaleString('hu-HU'));
    $('#brutto-couponed-sum-' + entry.id).html(entry.brutto_couponed_sum.toLocaleString('hu-HU'));
}

function updateBruttoCreditedSumWithShipping() {

    var paymentPrice = 0;

    if ($('.payment-method-select:checked').length > 0) {
        paymentPrice = Math.round($('.payment-method-select:checked').attr('data-price'));
    }

    var deliveryPrice = 0;

    if ($('.shipping-method-select:checked').length > 0) {
        deliveryPrice = Math.round($('.shipping-method-select:checked').attr('data-price'));
    }

    var totalPrice = Math.round($('#brutto_credited_sum').attr('data-brutto-credited-sum')) + deliveryPrice + paymentPrice;

    $('#brutto_credited_sum_with_shipping').html(totalPrice.toLocaleString('hu-HU'));
    $('#brutto_credited_sum_with_shipping').attr('data-total-price', totalPrice);
}

function updateCartInfo(cart) {
    $('.cart-entry_count').removeClass('hidden').html(cart.product_count);

    if (cart.product_count > 0) {
        $('#cart-entry_count_message').html(cart.product_count + ' termék a kosárban');
    } else {
        $('#cart-entry_count_message').html('Még üres a kosarad');
    }

    if (parseInt(cart.product_count) > 9) {
        $('.more').addClass('more-than-ten').html('9+');
    } else {
        $('.more').removeClass('more-than-ten');
    }

    $('.add-to-cart-btn .btn-label .cart-counter').remove();

    $.each(cart['entry_quantities'], function (index, value) {
        updateProductCounter(index, value);
    });
}

function updateProductCounter(id, value) {
    var info = '<span class="cart-counter">' + value + '</span>';

    if (value > 9) {
        info = '<span class="cart-counter">9+</span>';
    }

    $('[data-product="' + id + '"] .btn-label').prepend(info);
}

function handleCartButtons(inputField) {
    if (parseInt(inputField.val()) <= 1) {
        $('#btn-decrease-' + inputField.data('entryId')).attr('disabled', 'disabled');
    } else {
        $('#btn-decrease-' + inputField.data('entryId')).removeAttr('disabled');
    }
}

$('.product-quantity-input').each(function () {
    handleCartButtons($(this));
});

var updateCartTimeout = [];

$('.product-quantity-input').on('change', function () {

    var inputField = $(this);
    var inputId = $(this).data('inputId');
    if (updateCartTimeout[inputId] === undefined) {
        updateCartTimeout[inputId] = null;
    }
    window.clearTimeout(updateCartTimeout[inputId]);
    updateCartTimeout[inputId] = null;
    handleCartButtons(inputField);

    updateCartTimeout[inputId] = window.setTimeout(function () {
        updateCartEntry(inputField);
    }, 500);
});

$('.product-quantity-input').on('keyup', function () {
    var inputField = $(this);
    var inputId = $(this).data('inputId');
    if (updateCartTimeout[inputId] === undefined) {
        updateCartTimeout[inputId] = null;
    }
    window.clearTimeout(updateCartTimeout[inputId]);
    updateCartTimeout[inputId] = null;
    handleCartButtons(inputField);

    updateCartTimeout[inputId] = window.setTimeout(function () {
        updateCartEntry(inputField);
    }, 800);
});

$('.btn.btn-decrease').on('click', function (event) {
    event.preventDefault();
    var newVal = parseInt($('#' + $(this).data('inputId')).val()) - 1;
    $('#' + $(this).data('inputId')).val(newVal);
    $('#' + $(this).data('inputId')).attr('value', newVal);
    $('#' + $(this).data('inputId')).change();
});

$('.btn.btn-increase').on('click', function (event) {
    event.preventDefault();
    var newVal = parseInt($('#' + $(this).data('inputId')).val()) + 1;
    $('#' + $(this).data('inputId')).val(newVal);
    $('#' + $(this).data('inputId')).attr('value', newVal);
    $('#' + $(this).data('inputId')).change();
});

function updateCartEntry(inputField) {
    handleRefreshButton(inputField, 1);
    var cart_data_api = new cartDataApi(),
        item_qty = parseInt(inputField.val()),
        entry_id = inputField.data('entryId');

    if (isNaN(item_qty) || item_qty == 0) {
        setTimeout(function () {
            item_qty = parseInt(inputField.val());
            if (isNaN(item_qty) || item_qty == 0) {
                swal('Oops...', 'Hibás mennyiség!', 'error');
                handleRefreshButton(inputField, 0);
                inputField.val(inputField.data('originalValue'));
                inputField.focus();
            }
        }, 1000);
        return;
    }

    if (!isNaN(entry_id)) {
        cart_data_api.update(entry_id, item_qty, function (response) {
            cart_display_api.initalize(response.cart).redrawTable();
            handleRefreshButton(inputField, 0);
            if (response.state == 'success') {
                inputField.data('originalValue', item_qty);
                /*swal('Szuper!','Tétel mennyisége frissítve!','success');*/
                if (typeof updateCartPrices === "function") {
                    updateCartPrices(response.cart);
                }
            }
        }, function (response) {
            swal('Oops...', 'Nem sikerült a tétel mennyiségét frissíteni!', 'error');
        });
    } else {
        swal('Oops...', 'Nem kaptam meg a product_id-t!', 'error');
        handleRefreshButton(inputField, 0);
    }
}

function handleRefreshButton(inputField, update) {
    if (update == 1) {
        $('#btn-remove-' + inputField.data('entryId')).addClass('hidden');
        $('#btn-refresh-' + inputField.data('entryId')).removeClass('hidden');
    } else {
        $('#btn-refresh-' + inputField.data('entryId')).addClass('hidden');
        $('#btn-remove-' + inputField.data('entryId')).removeClass('hidden');
    }
}
