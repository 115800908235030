var cartDisplayApi = function cartDisplayApi(cart_json) {

    var containers = {
        free_shipping_info: $('#free-shipping-info'),
        brutto_credited_sum: $('#brutto_credited_sum'),
        brutto_special_sum: $('#brutto_special_sum_container'),
        credit_donateable: $('#credit_donateable'),
        apply_coupon_text: $('#apply-coupon-text'),
        max_exchangeable_credits: $('#max-exchangeable-credits')
    },
    inputs = {
        apply_credit: $('#apply-credit'),
        apply_coupon: $('#apply-coupon'),
        coupon_code: $('#coupon-code'),
        credit_by_registration: $('#credit_by_registration'),
        shipping_method: '.shipping-method-select'
    },
        currencyinfo = {
        locale: 'hu-HU',
        postfix: ',-&nbsp;Ft'
    },
        layout = {
        product: {
            price_discount: '<div class="row">' + '<div class="col-24">' + '<s><span id="brutto-couponed-price-#index#">#old_price#</span>#currency#</s>' + '</div>' + '</div>' + '<div class="row">' + '<div class="col-24">' + '<span id="brutto-couponed-price-#index#">#price#</span>#currency#' + '</div>' + '</div>',

            total_discount: '<div class="row">' + '<div class="col-24">' + '<s><span id="brutto-couponed-sum-#index#">#old_price#</span>#currency#</s>' + '</div>' + '</div>' + '<div class="row">' + '<div class="col-24">' + '<span id="brutto-couponed-sum-#index#">#price#</span>#currency#' + '</div>' + '</div>'
        },
        totals: {
            without_discount: '<div><span class="label">Kedvezmény nélkül:</span> <span class="price-old"><span id="brutto_special_sum">#price#</span>#currency#</span></div>'
        },
        shipping: {
            no: 'Ha további <span>#value##currency#</span> értékben vásárolsz, a szállítás ingyenes!',
            yes: '<span class="_purple RobotoBold text-uppercase">Ingyenes szállítás!</span>'
        }
    };

    /*************************************************
    * Number to currency coversion
     *************************************************/

    var currency = function currency(num) {
        return Math.round(num).toLocaleString(currencyinfo.locale);
    };

    /*************************************************
    * Create new cart row from last row's clone
     *************************************************/

    var createCartRow = function createCartRow(index, entry, has_discount) {
        var last_product = $('.cart-table .cart-table-row:last'),
            clone = last_product.clone();

        clone.attr('id', 'cart-row-' + index).find('.product-name').text(entry.product.name);

        if (has_discount) {
            clone.find('.product-price').html(layout.product.price_discount.replace(/#index#/g, index).replace(/#old_price#/g, currency(entry.brutto_price)).replace(/#price#/g, currency(entry.brutto_couponed_sum)).replace(/#currency#/g, currencyinfo.postfix));
            clone.find('.product-total').html(layout.product.total_discount.replace(/#index#/g, index).replace(/#old_price#/g, currency(entry.brutto_sum)).replace(/#price#/g, currency(entry.brutto_couponed_sum)).replace(/#currency#/g, currencyinfo.postfix));
        } else {
            clone.find('.product-price span').html(currency(entry.brutto_couponed_price)).attr('id', 'brutto-couponed-price-' + index);
            clone.find('.product-total span').html(currency(entry.brutto_couponed_sum)).attr('id', 'brutto-couponed-sum-' + index);
        }

        clone.find('.product-info .img-container img').attr('src', entry.product.image_url + '?w=235&h=180');
        clone.find('.product-quantity-input').val(entry.quantity).attr('data-entry-id', index).attr('id', 'product-quantity-' + index).addClass('click-disabled');
        clone.find('.btn-decrease, .btn-increase').attr('data-input-id', 'product-quantity-' + index).attr('id', 'btn-increase-' + index).addClass('click-disabled');
        clone.find('.btn-remove').attr('data-entry-id', index).attr('id', 'btn-remove-' + index).addClass('click-disabled');
        clone.find('.cart-actions').children('.btn-refresh').addClass('hidden');
        clone.find('.cart-actions').children('.btn-remove').removeClass('hidden');

        return clone;
    };

    /*************************************************
    * Check if product row is in cart table
     *************************************************/

    var cartRowExists = function cartRowExists(index) {
        return $('#cart-row-' + index).length > 0;
    };

    /*************************************************
    * Check cart discount
     *************************************************/

    var hasDiscount = function hasDiscount(type, cart) {
        var discount = {
            coupon: (parseInt(cart.coupon_id) || 0) > 0,
            credit: inputs.apply_credit.is(':checked') && (parseInt(cart.max_exchangeable_credits) || 0) > 0
        };

        return discount[type];
    };

    /*************************************************
    * Call custom cart events on $(document) object
     *************************************************/

    var callCustomEvent = function callCustomEvent(event, params) {
        $(document).trigger(event, params === undefined ? [] : params);
    };

    /* Public methods */

    /*************************************************
    * Initalize when cart object created
     *************************************************/

    cartDisplayApi.prototype.initalize = function (cart) {
        if (cart !== undefined) {
            this.cart = cart;
        }

        return this;
    };

    /*************************************************
    * Redraw cart table
     *************************************************/

    cartDisplayApi.prototype.redrawTable = function () {
        callCustomEvent('beforeCartChange', [this.cart]);

        var processed = [],
            _this = this;
        $.each(this.cart.entries, function (index, entry) {
            if (!cartRowExists(index)) {
                createCartRow(index, entry, hasDiscount('coupon', _this.cart)).insertAfter($('.cart-table .cart-table-row:last'));
            } else {
                $('#brutto-couponed-price-' + entry.id).html(currency(entry.brutto_couponed_price));
                $('#brutto-couponed-sum-' + entry.id).html(currency(entry.brutto_couponed_sum));
            }

            processed.push(parseInt(index));
        });

        // Throw back

        $('.cart-table-row').each(function () {
            var id = parseInt($(this).prop('id').replace(/cart-row-/g, '')) || 0;

            if (id > 0 && processed.indexOf(id) == -1) {
                $('#cart-row-' + id).remove();
            }
        });

        // Call custom event

        callCustomEvent('afterCartChange', [this.cart]);
    };

    /*************************************************
    * Redraw discount informations
     *************************************************/

    cartDisplayApi.prototype.redrawDiscount = function (callback) {
        if (hasDiscount('coupon', this.cart) || hasDiscount('credit', this.cart)) {
            var price_difference = Math.round(this.cart.brutto_special_sum) !== Math.round(this.cart.brutto_credited_sum);

            if (price_difference) {
                containers.brutto_special_sum.html(layout.totals.without_discount.replace(/#price#/g, currency(this.cart.brutto_special_sum)).replace(/#currency#/g, currencyinfo.postfix));
            } else {
                containers.brutto_special_sum.html('');
            }

            if (hasDiscount('coupon', this.cart)) {
                inputs.apply_coupon.val(0);
                containers.apply_coupon_text.html('Leválasztás');
                inputs.coupon_code.attr('readonly', 'readonly');
            } else if (hasDiscount('credit', this.cart)) {
                containers.max_exchangeable_credits.text(currency(this.cart.max_exchangeable_credits_sum));
            }
        } else {
            containers.brutto_special_sum.html('');
            inputs.apply_coupon.val(1);
            containers.apply_coupon_text.html('Beváltom');
            inputs.coupon_code.val('').removeAttr('readonly');
        }

        containers.brutto_credited_sum.html(Math.round(this.cart.brutto_credited_sum).toLocaleString('hu-HU'));
        containers.brutto_credited_sum.attr('data-brutto-credited-sum', this.cart.brutto_credited_sum);
        containers.credit_donateable.html('<b>' + (parseInt(inputs.credit_by_registration.val()) +  parseInt(this.cart.credit_donateable)) + '</b>');

        if (callback !== undefined) {
            callback.call();
        }

        return this;
    };

    /*************************************************
    * Redraw shipping informations
     *************************************************/

    cartDisplayApi.prototype.redrawShipping = function (callback) {

        if (typeof this.cart === 'undefined') {
            return false;
        }

        var total_shipping_cost     = 0,
            actual_shipping_cost    = 0,
            shipping_selected       = false,
            shipping_prices_server  = typeof this.cart.shipping_prices !== 'undefined' && Object.keys(this.cart.shipping_prices).length > 0;

        if (shipping_prices_server) {
            $.each(this.cart.shipping_prices, function (s_id, method) {
                var shipping_dom = $('#shipping-' + s_id);
                shipping_dom.attr('data-price', method.price).closest('.shipping-method-item').find('.shipping-price-label').text(currency(method.price));

                $.each(method.payment, function(p_id, price) {
                    $('#payment-methods-' + s_id).find('#payment-method-' + p_id).find('#pay-' + p_id).attr('data-price', price);
                    $('#payment-methods-' + s_id).find('#payment-method-' + p_id + ' .payment-price-label').text(currency(price));
                });
            });
        }

        $(inputs.shipping_method).each(function () {
            var price = parseInt($(this).attr('data-price')) || 0;

            total_shipping_cost += price;

            if ($(this).is(':checked')) {
                shipping_selected = true;
                actual_shipping_cost += price;
            }
        });

        var free_shipping = total_shipping_cost === 0 || (shipping_selected && actual_shipping_cost == 0);

        if (shipping_prices_server) {
            // Show free shipping label
            if (free_shipping) {
                containers.free_shipping_info.html(layout.shipping.yes);
            } else {
                if (this.cart.brutto_couponed_sum >= cart.free_shipping) {
                    containers.free_shipping_info.html(layout.shipping.yes);
                } else {
                    containers.free_shipping_info.html(layout.shipping.no.replace(/#value#/, currency(this.cart.free_shipping - this.cart.brutto_couponed_sum)).replace(/#currency#/g, currencyinfo.postfix));
                }
            }
        } else {
            if (free_shipping || this.cart.brutto_couponed_sum >= this.cart.free_shipping) {
                containers.free_shipping_info.html(layout.shipping.yes);
            } else {
                containers.free_shipping_info.html(layout.shipping.no.replace(/#value#/g, currency(this.cart.free_shipping - this.cart.brutto_couponed_sum)).replace(/#currency#/g, currencyinfo.postfix));
            }
        }

        if (callback !== undefined) {
            callback.call();
        }

        /**/
        return this;
    };

    this.initalize(cart_json);

    return this;
};