var Product = function(data) {
    this.id = data.id;
    this.name = data.name || '';
    this.quantity = data.quantity || 1;
    this.price = data.price || 0;
};

Product.prototype = {
    id              :   0,
    name            :   '',
    quantity        :   0,
    price           :   0,
    options         :   [],
    
    getId           :   function() {
        return this.id;
    },
    
    getName         :   function() {
        return this.name;
    },
    
    getQuantity     :   function() {
        return this.quantity;
    },
    
    getOptions      :   function() {
        return this.options;
    },
    
    getPrice        :   function() {
        return parseInt(this.price) || 0;
    },
    
    getDiscount     :   function() {
        return 0;
    },
    
    // Setters
    
    setQuantity     :   function(quantity) {
        this.quantity = parseInt(quantity) || 1;
        
        return this;
    },
    
    setPrice        :   function(price) {
        this.price = price;
        
        return this;
    },
    
    setOptions      :   function(options) {
        this.options = options;
    }
};